export const addCommasToNumber = (num) => {

    if (num === undefined || num === null || num === '') {
        console.log('addCommasToNumber received empty input:', num);
        return '0.00';
    }

    if (typeof num === 'string') {
        num = num.replace(/[$,]/g, '');
    }

    const numericValue = parseFloat(num);

    //Defensive check for Number
    if (isNaN(numericValue)) {
        console.log('addCommasToNumber received invalid number:', num);
        return '0.00';
    }

    return numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};