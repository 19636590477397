import React, { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress, Snackbar, Alert, Grid, Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../../../../api/axiosWrapper';
import { colors } from '../../../../themes/theme';

// Styled components for easy editing
const ProfileContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  minHeight: '100vh',
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.default,
}));

const ProfileTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  width: '100px',
  marginRight: theme.spacing(2),
  borderRadius: '20px',
}));

const SaveButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: colors.success, 
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.success, 
  },
}));

const CancelButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: colors.error, 
  color: colors.white,
  marginLeft: theme.spacing(2),
  '&:hover': {
    backgroundColor: colors.error, 
  },
}));

const EditButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: colors.primary, 
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.primaryHover,
  },
}));

const ProfileInfo = ({ currentUser }) => {
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    company: '',
  });
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setProfile(currentUser);
    }
  }, [currentUser]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.put('/updateProfile', profile);
      console.log('Profile updated successfully:', response.data);
      setSnackbarMessage('Profile updated successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnackbarMessage('Error updating profile. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
      setIsEditing(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCancel = () => {
    setProfile(currentUser);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <ProfileContainer>
      <Typography variant="h4" gutterBottom>Profile Information</Typography>
      <Box component="form" sx={{ width: '100%', maxWidth: '800px', mt: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ProfileTextField
              label="First Name"
              name="firstName"
              value={profile.firstName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ProfileTextField
              label="Last Name"
              name="lastName"
              value={profile.lastName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <ProfileTextField
              label="Email"
              name="emailId"
              value={profile.emailId}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <ProfileTextField
              label="Business Name"
              name="company"
              value={profile.company}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              disabled={!isEditing}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          {isEditing ? (
            <>
              <SaveButton
                variant="contained"
                onClick={handleSave}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Save'}
              </SaveButton>
              <CancelButton
                variant="contained"
                onClick={handleCancel}
              >
                Cancel
              </CancelButton>
            </>
          ) : (
            <EditButton
              variant="contained"
              onClick={handleEdit}
            >
              Edit
            </EditButton>
          )}
        </Box>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ProfileContainer>
  );
};

export default ProfileInfo;