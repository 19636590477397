import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useState, useEffect, useCallback } from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import { getTransactions } from '../../services/api/transactions-service';

dayjs.extend(utc);
dayjs.extend(timezone);

const useTransactions = () => {
  const [data, setData] = useState({
    transactions: [],
    rows: [],
    accounts: [],
    minDateRange: null,
    maxDateRange: null,
    loadingData: true,
  });

  const {
    transactions,
    rows,
    accounts,
    minDateRange,
    maxDateRange,
    loadingData,
  } = data;

  const processTransactions = useCallback((responseData) => {
    let uniqueAccounts = new Set();

    const validTransactions = responseData.transactions.filter(
      (transaction) => transaction.is_removed !== 1
    );

    const newRows = validTransactions.map((transaction) => {
      const floatAmount = parseFloat(transaction.amount);
      uniqueAccounts.add(transaction.account?.name ?? 'Other');

      const date = dayjs.utc(transaction.authorized_date).local();

      return {
        id: transaction.transaction_id ?? randomId(),
        date: date.format('YYYY-MM-DD HH:mm:ss'),
        description: transaction.name,
        account: transaction.account?.name ?? 'Other',
        category: transaction?.category?.[0],
        amount: floatAmount,
        receiptUrl: transaction.receiptUrl,
      };
    });

    const localMinDateRange = dayjs
      .utc(responseData.minDateRange)
      .local()
      .startOf('day')
      .toDate();
    const localMaxDateRange = dayjs
      .utc(responseData.maxDateRange)
      .local()
      .endOf('day')
      .toDate();

    setData({
      transactions: validTransactions,
      rows: newRows,
      accounts: Array.from(uniqueAccounts).map((name) => ({ name })),
      loadingData: false,
      minDateRange: localMinDateRange,
      maxDateRange: localMaxDateRange,
    });
  }, []);

  const reloadTransactions = useCallback(async () => {
    try {
      const response = await getTransactions();
      if (response.status === 200) {
        processTransactions(response.data);
      } else {
        throw new Error(
          `Failed to fetch transactions: Status ${response.status}`
        );
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  }, [processTransactions]);

  useEffect(() => {
    reloadTransactions();
  }, [reloadTransactions]);

  return {
    transactions,
    rows,
    accounts,
    loadingData,
    reloadTransactions,
    minDateRange,
    maxDateRange,
  };
};

export default useTransactions;
