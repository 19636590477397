import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { SButton } from '../Login/style';
import Alert from '@mui/material/Alert';
import SubmitPasswordForm from '../../common/SubmitPasswordForm';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="left" {...props}>
      {'By signing up, you are indicating that you have read and agree to the '}
      <Link href="./termsOfUse.html" underline="hover">
        Terms of Use
      </Link>
      {' and '}
      <Link href="./privPolicy.html" underline="hover">
        Privacy Policy
      </Link>
      {'.'}
    </Typography>
  );
}

const SignUp = ({
  handleSubmit,
  registerUser,
  setRegisterUser,
  handleSignInClick,
  errorMessage,
  setErrorMessage,
}) => {
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    letter: false,
    number: false,
    specialChar: false,
    validChars: true,
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      letter: /[A-Za-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      validChars: /^[A-Za-z\d!@#$%^&*]*$/.test(password),
    };
    setPasswordCriteria(criteria);
    const strength =
      Object.values(criteria).reduce((acc, curr) => acc + (curr ? 1 : 0), 0) *
      20;
    setPasswordStrength(strength);
    return (
      criteria.length &&
      criteria.letter &&
      criteria.number &&
      criteria.specialChar &&
      criteria.validChars
    );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!registerUser.emailId) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(registerUser.emailId)) {
      formErrors.email = 'Invalid email address';
    }

    if (!registerUser.password) {
      formErrors.password = 'Password is required';
    } else if (!validatePassword(registerUser.password)) {
      formErrors.password = 'Password does not meet the required criteria.';
    }

    if (!registerUser.confirmPassword) {
      formErrors.confirmPassword = 'Please confirm your password';
    } else if (registerUser.password !== registerUser.confirmPassword) {
      formErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(formErrors).length === 0) {
      handleSubmit(event);
    } else {
      setErrors(formErrors);
    }
  };

  const handleError = () => {
    if (errorMessage) setErrorMessage('');
    setErrors({ email: '', password: '', confirmPassword: '' });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            style={{ fontWeight: 'bold' }}
          >
            Create Your Account Today!
          </Typography>
          <Typography variant="body1">
            Already have an account?{' '}
            <Link href="#" component="span">
              <Typography
                component="span"
                variant="body1"
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={handleSignInClick}
              >
                Sign in
              </Typography>
            </Link>
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleFormSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="span" variant="h6">
                  Email
                </Typography>
                <TextField
                  sx={{ mt: 1.2 }}
                  required
                  fullWidth
                  id="email"
                  label="Enter your email"
                  name="email"
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={(event) => {
                    setRegisterUser({
                      ...registerUser,
                      emailId: event.target.value,
                    });
                    handleError();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitPasswordForm
                  handleError={handleError}
                  errors={errors}
                  registerUser={registerUser}
                  setRegisterUser={setRegisterUser}
                  validatePassword={validatePassword}
                  passwordStrength={passwordStrength}
                  passwordCriteria={passwordCriteria}
                />
              </Grid>
            </Grid>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 3 }}>
                {errorMessage}
              </Alert>
            )}
            <SButton type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
              Get Started
            </SButton>
          </Box>
        </Box>
        <Copyright sx={{ mt: 3 }} />
      </Container>
    </>
  );
};
export default SignUp;
