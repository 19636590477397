import React, { useState, useEffect } from 'react';
import { DashboardProvider } from '../../../components/common/contexts/DashboardContext';
import MetricsDisplay from '../../../components/features/Metrics Display';
import useTransactions from '../../../components/common/hooks/useTransactions';
import AlertPopup from '../../../components/common/AlertPopup';
import { Box } from '@mui/material';
import { colors } from '../../../themes/theme';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavigationTabs } from '../../../components/features/NavigationTabs';
import { useLocation } from 'react-router-dom';
import useCategories from '../../../components/common/hooks/useCategories';
import CircularProgress from '@mui/material/CircularProgress';

const Home = () => {
  const [openAlert, setOpenAlert] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [timePeriod, setTimePeriod] = useState('all');
  const [filterState, setFilterState] = useState('All');
  const [isEditing, setIsEditing] = useState(false);
  const {
    transactions,
    rows: initialRows,
    accounts,
    loadingData: initialLoadingData,
    reloadTransactions,
    minDateRange,
    maxDateRange,
  } = useTransactions();

  const categories = useCategories();

  const [rows, setRows] = useState(initialRows);
  const [loadingData, setLoadingData] = useState(initialLoadingData);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setRows(initialRows);
  }, [initialRows]);

  useEffect(() => {
    setLoadingData(initialLoadingData);
  }, [initialLoadingData]);

  const handleTimePeriodChange = (newTimePeriod) => {
    setTimePeriod(newTimePeriod);
  };

  const handleFilterChange = (newFilterState) => {
    setFilterState(newFilterState);
  };

  if (loadingData) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardProvider
      minDateRange={minDateRange}
      maxDateRange={maxDateRange}
      reloadTransactions={reloadTransactions}
    >
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.gray,
          height: 'fit-content',
          padding: '10px 0px',
          gap: '50px',
        }}
      >
        <NavigationTabs pathname={location.pathname} isEditing={isEditing} />
        {location.pathname !== '/dashboard/reports' && (
          <MetricsDisplay
            transactions={transactions}
            onTimePeriodChange={handleTimePeriodChange}
            filterState={filterState}
          />
        )}
        <Outlet
          context={{
            transactions,
            categories,
            timePeriod,
            rows,
            setRows,
            accounts,
            loadingData,
            setLoadingData,
            setOpenAlert,
            setAlertSeverity,
            setAlertMessage,
            reloadTransactions,
            handleFilterChange,
            setIsEditing,
          }}
        />
        {openAlert && (
          <AlertPopup
            open={openAlert}
            setOpen={setOpenAlert}
            alertMessage={alertMessage}
            alertSeverity={alertSeverity}
          />
        )}
      </Box>
    </DashboardProvider>
  );
};

export default Home;
