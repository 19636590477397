import React, { useCallback, useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineRounded from '@mui/icons-material/HelpOutlineRounded'; 
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { CategoryInfoTooltip } from '../../styles/style';
import { useMediaQuery, useTheme } from '@mui/material';

export const CategorySelect = ({ value, onChange, row, categories }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const categoryDetails = useMemo(() => categories.allCategories.reduce((acc, curr) => ({
    ...acc,
    [curr.label]: curr.description,
  }), {}), [categories]);

  const getCategoryToDisplay = useCallback(() => {
    const { incomeCategories, expenseCategories, allCategories } = categories || {};
    if (row.amount > 0) {
      return incomeCategories;
    } else if (row.amount < 0) {
      return expenseCategories;
    } else {
      return allCategories;
    }
  }, [categories, row.amount]);

  const renderValue = (selected) => {
    const selectedCategory = categories?.allCategories?.find(
      (category) => category.value === selected
    );
    return selectedCategory ? selectedCategory.label : '';
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 175 }}>
      <Select
        value={value}
        onChange={onChange}
        id="category-select"
        renderValue={renderValue}
      >
        {getCategoryToDisplay().map((category, index) =>
          category.value && category.isSelectable ? (
            <MenuItem key={category.value} value={category.value}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {category.label}
                {categoryDetails[category.label] && (
                  <CategoryInfoTooltip
                    title={categoryDetails[category.label]}
                    arrow
                    placement={isSmallScreen ? 'top' : 'right'}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </CategoryInfoTooltip>
                )}
              </Box>
            </MenuItem>
          ) : (
            <ListSubheader key={index}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                {category.label.toUpperCase()}
                {categoryDetails[category.label] && (
                  <CategoryInfoTooltip
                    title={categoryDetails[category.label]}
                    arrow
                    placement={isSmallScreen ? 'top' : 'right'}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <HelpOutlineRounded fontSize="small" />
                    </IconButton>
                  </CategoryInfoTooltip>
                )}
              </Box>
            </ListSubheader>
          )
        )}
      </Select>
    </FormControl>
  );
};
