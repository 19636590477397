import React from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Tooltip, Button } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { colors } from '../../../themes/theme.js';

const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minDateRange,
  maxDateRange,
  handleReset,
  isResetDisabled,
}) => {
  const handleDateChange = (setter) => (newDate) => {
    const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
    if (dayjs(formattedDate).isValid()) {
      setter(formattedDate);
    }
  };

  return (
    <Row>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          inputFormat="MM-DD-YYYY"
          value={dayjs(startDate)}
          minDate={dayjs(minDateRange)}
          maxDate={dayjs(endDate)}
          onChange={handleDateChange(setStartDate)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <span style={{ fontSize: '15px' }}>to</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          inputFormat="MM-DD-YYYY"
          value={dayjs(endDate)}
          minDate={dayjs(startDate)}
          maxDate={dayjs(maxDateRange)}
          onChange={handleDateChange(setEndDate)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Tooltip title="Reset Date Range" placement="right" arrow>
        <span>
          <ResetButton
            disabled={isResetDisabled}
            variant="contained"
            color="primary"
            onClick={handleReset}
            aria-label="Reset date range"
          >
            <SmallRestartAltIcon />
          </ResetButton>
        </span>
      </Tooltip>
    </Row>
  );
};

export default React.memo(DateRangePicker);

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: '0 auto';
`;

const StyledDatePicker = styled(DatePicker)`
  & .MuiInputBase-root {
    padding: 4px 8px;
    width: 137px;
    & input {
      padding: 2px;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 5px;
  }
  svg {
    padding: 2px;
  }
`;

const ResetButton = styled(Button)`
  background-color: ${colors.primary};
  text-transform: none;
  border-radius: 50px;
  box-shadow: none;
  color: ${colors.white};
  min-width: 40px;
  height: 30px;
  padding: 0;
  &:hover {
    background-color: ${colors.hoverBg};
  }
`;

const SmallRestartAltIcon = styled(RestartAltIcon)`
  font-size: 1.2rem;
`;
