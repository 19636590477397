import React from 'react';
import DateRangePicker from './DateRangeComponent.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IndicatorBox } from '../../styles/style.js';

dayjs.extend(utc);
dayjs.extend(timezone);

const DateRangeIndicator = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
}) => {
  return (
    <IndicatorBox sx={{ maxWidth: '400px' }}>
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        minDateRange={minDateRangeLocal}
        maxDateRange={maxDateRangeLocal}
        handleReset={handleReset}
        isResetDisabled={(() => {
          const isDisabled =
            dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
            dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day');
          return isDisabled;
        })()}
      />
    </IndicatorBox>
  );
};

export default React.memo(DateRangeIndicator);
