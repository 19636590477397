import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Link, useLocation } from 'react-router-dom';
import logoImg from '../../../assets/OnebitMark_White.png';
import homePageImage from '../../../assets/home_page_image.png';
import { colors } from '../../../themes/theme';

const drawerWidth = 240;

const Sidebar = ({ handleDrawerToggle, isSmallScreen, mobileOpen }) => {
  const location = useLocation();

  const menuItems = [
    { text: 'Profile', icon: <AccountCircle fontSize="large" />, path: '/settings/profile' },
    { text: 'Bank Account', icon: <AccountBalanceIcon fontSize="large" />, path: '/settings/bank-account' },
  ];

  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: colors.lightGray }}>
      <Box sx={{ background: colors.primary, color: colors.white, padding: '13px', display: 'flex', alignItems: 'center' }}>
        <Link to="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
          <img src={logoImg} alt="Logo" style={{ height: '40px', marginRight: 10 }} />
          <img src={homePageImage} alt="Home" style={{ height: '25px', filter: 'brightness(0) invert(1)' }} />
        </Link>
      </Box>

      <Box sx={{ flexGrow: 1, backgroundColor: colors.lightGray }}>
        <Divider sx={{ borderColor: colors.white, mb: 3, mt: 0 }} />

        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              component={Link}
              to={item.path}
              key={item.text}
              selected={location.pathname === item.path}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: colors.primaryHover,
                  color: colors.white,
                  '& .MuiListItemIcon-root': {
                    color: colors.white,
                  },
                  '&:hover': {
                    backgroundColor: colors.primary,
                    '& .MuiListItemIcon-root': {
                      color: colors.white,
                    },
                  },
                },
                '&:hover': {
                  backgroundColor: colors.gray,
                  color: colors.black,
                  '& .MuiListItemIcon-root': {
                    color: 'inherit',
                  },
                },
                marginBottom: 3,
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <ListItemIcon sx={{ color: location.pathname === item.path ? colors.white : 'inherit' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} sx={{ color: location.pathname === item.path ? colors.white : 'inherit' }} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
      {isSmallScreen ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
