import React, { useState, useMemo, useCallback } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DateRangePicker from './DateRangeComponent';
import { colors } from '../../../themes/theme.js';
import dayjs from 'dayjs';

const TimeAccordion = ({
  timePeriod,
  options,
  selected,
  setSelected,
  activeTimePeriod,
  onAccordionChange,
}) => {
  const isAllSelected = useMemo(
    () => selected.length === options.length,
    [selected, options]
  );
  const hasSelections = useMemo(() => selected.length > 0, [selected]);

  const sortedOptions = useMemo(() => {
    if (timePeriod === 'week') {
      return [...options].sort((a, b) => {
        const dateA = dayjs(a.split(' - ')[0] + ', ' + a.split(', ')[1]);
        const dateB = dayjs(b.split(' - ')[0] + ', ' + b.split(', ')[1]);
        return dateA.diff(dateB);
      });
    } else if (timePeriod === 'month') {
      return [...options].sort((a, b) => {
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');
        const dateA = dayjs(`${monthA} 1, ${yearA}`);
        const dateB = dayjs(`${monthB} 1, ${yearB}`);
        return dateA.diff(dateB);
      });
    } else if (timePeriod === 'quarter') {
      return [...options].sort((a, b) => {
        const [_, yearA, quarterA] = a.match(/Q(\d) (\d{4})/);
        const [__, yearB, quarterB] = b.match(/Q(\d) (\d{4})/);
        return yearA - yearB || quarterA - quarterB;
      });
    } else if (timePeriod === 'year') {
      return [...options].sort((a, b) => parseInt(a) - parseInt(b));
    }
    return options;
  }, [options, timePeriod]);

  const handleChipClick = useCallback(
    (item) => {
      setSelected((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
    },
    [setSelected]
  );

  const handleSelectAll = useCallback(() => {
    setSelected([...sortedOptions]);
  }, [sortedOptions, setSelected]);

  const handleDeselectAll = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  return (
    <Accordion
      expanded={activeTimePeriod === timePeriod}
      onChange={onAccordionChange(timePeriod)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${timePeriod}-header`}
      >
        <Typography>
          {timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1)}
        </Typography>
        {hasSelections && (
          <FiberManualRecordIcon
            sx={{ fontSize: 12, color: colors.primary, ml: 1 }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {sortedOptions.map((option) => (
            <Chip
              key={option}
              label={option}
              clickable
              onClick={() => handleChipClick(option)}
              color={selected.includes(option) ? 'primary' : 'default'}
              variant={selected.includes(option) ? 'filled' : 'outlined'}
            />
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-start" mt={2} gap={2}>
          <Button
            variant="text"
            sx={{ textTransform: 'none', color: colors.primary }}
            onClick={isAllSelected ? handleDeselectAll : handleSelectAll}
          >
            {isAllSelected ? 'Deselect All' : 'Select All'}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

function TimeTabsModal({
  onTimePeriodChange,
  setActiveTimeTab,
  minDateRangeLocal,
  maxDateRangeLocal,
  handleReset,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectedWeeks,
  setSelectedWeeks,
  weekOptions,
  selectedMonths,
  setSelectedMonths,
  monthOptions,
  selectedQuarters,
  setSelectedQuarters,
  quarterOptions,
  selectedYears,
  setSelectedYears,
  yearOptions,
  closeModal,
}) {
  const [activeTimePeriod, setActiveTimePeriod] = useState(null);
  const [allButtonClicked, setAllButtonClicked] = useState(false);

  const handleAccordionChange = useCallback(
    (timePeriod) => (event, isExpanded) => {
      if (isExpanded) {
        onTimePeriodChange(timePeriod);
        setActiveTimeTab(timePeriod);
        setActiveTimePeriod(timePeriod);
        clearOtherSelections(timePeriod);
      } else {
        setActiveTimePeriod(null);
      }
    },
    [onTimePeriodChange, setActiveTimeTab]
  );

  const clearOtherSelections = useCallback(
    (timePeriod) => {
      if (timePeriod !== 'week') setSelectedWeeks([]);
      if (timePeriod !== 'month') setSelectedMonths([]);
      if (timePeriod !== 'quarter') setSelectedQuarters([]);
      if (timePeriod !== 'year') setSelectedYears([]);
    },
    [setSelectedWeeks, setSelectedMonths, setSelectedQuarters, setSelectedYears]
  );

  const handleAllButtonClick = useCallback(() => {
    setAllButtonClicked(true);
    setSelectedWeeks([]);
    setSelectedMonths([]);
    setSelectedQuarters([]);
    setSelectedYears([]);
    setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
    setEndDate(dayjs(maxDateRangeLocal).format('YYYY-MM-DD'));
    setActiveTimePeriod('all');
    onTimePeriodChange('all');
    setActiveTimeTab('all');
    closeModal();
  }, [
    setSelectedWeeks,
    setSelectedMonths,
    setSelectedQuarters,
    setSelectedYears,
    setStartDate,
    setEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
    onTimePeriodChange,
    setActiveTimeTab,
    closeModal,
  ]);

  return (
    <Box>
      <Box mt={2} mb={3} display="flex" justifyContent="center">
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          minDateRange={minDateRangeLocal}
          maxDateRange={maxDateRangeLocal}
          handleReset={handleReset}
          isResetDisabled={(() => {
            const isDisabled =
              dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
              dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day');
            return isDisabled;
          })()}
        />
      </Box>

      <TimeAccordion
        timePeriod="week"
        options={weekOptions}
        selected={selectedWeeks}
        setSelected={setSelectedWeeks}
        activeTimePeriod={activeTimePeriod}
        onAccordionChange={handleAccordionChange}
      />

      <TimeAccordion
        timePeriod="month"
        options={monthOptions}
        selected={selectedMonths}
        setSelected={setSelectedMonths}
        activeTimePeriod={activeTimePeriod}
        onAccordionChange={handleAccordionChange}
      />

      <TimeAccordion
        timePeriod="quarter"
        options={quarterOptions}
        selected={selectedQuarters}
        setSelected={setSelectedQuarters}
        activeTimePeriod={activeTimePeriod}
        onAccordionChange={handleAccordionChange}
      />

      <TimeAccordion
        timePeriod="year"
        options={yearOptions}
        selected={selectedYears}
        setSelected={setSelectedYears}
        activeTimePeriod={activeTimePeriod}
        onAccordionChange={handleAccordionChange}
      />

      <Box mt={2} textAlign="center">
        <Button
          variant="contained"
          onClick={handleAllButtonClick}
          sx={{
            width: '100%',
            textTransform: 'none',
            justifyContent: 'space-between',
            padding: '12px 16px',
            fontSize: '1rem',
            backgroundColor: allButtonClicked ? colors.primary : colors.white,
            color: allButtonClicked ? colors.white : colors.black,
            '&:hover': {
              backgroundColor: colors.primaryHover,
              color: colors.white,
            },
            border: `1px solid ${colors.borderColor}`,
          }}
        >
          All
        </Button>
        <Button
          variant="outlined"
          sx={{
            mt: 2,
            width: '50%',
            textTransform: 'none',
            justifyContent: 'center',
            borderColor: colors.primary,
            color: colors.primary,
            '&:hover': {
              backgroundColor: colors.primary,
              color: colors.white,
            },
          }}
          onClick={closeModal}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}

export default React.memo(TimeTabsModal);
