import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import logoImg from '../../../assets/OnebitMark_White.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { colors } from '../../../themes/theme'; 

const Navbar = ({ currentUser, isLoggedIn, handleLogout, handleDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleSettings = () => {
    navigate('/settings/profile');
    handleMenuClose();
  };

  const handleLogoClick = () => {
    if (isLoggedIn) {
      navigate('/dashboard');
    } 
  };

  const getAvatarLetters = (email) => {
    if (!email) return '';
    return email.substring(0, 2).toUpperCase();
  };

  const isHomePage = currentPath === '/';
  const isSettingsPage = currentPath.startsWith('/settings');

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: colors.primary, boxShadow: 'none' }}>
        <Toolbar>
          {!isHomePage && isSmallScreen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{ marginRight: 5 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box component="div" sx={{ mr: 2, cursor: 'pointer' }} onClick={handleLogoClick}>
            <img src={logoImg} alt="logo" style={{ height: '40px' }} />
          </Box>
          {isSettingsPage && (
            <Typography
              variant="h6"
              sx={{
                ml: 25,
                flexGrow: 1,
                fontSize: 'xx-large',
                fontWeight: 'bold',
              }}
            >
              Settings
            </Typography>
          )}
          {isLoggedIn && currentUser && (
            <>
              <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>
              <Tooltip title="Notifications">
                <IconButton size="large" aria-label="show 0 new notifications" color="inherit">
                  <Badge badgeContent={0} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Box sx={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 0, mr: 3 }}>
                  {currentUser?.company || 'User'}
                </Typography>
              </Box>
              <Tooltip title="Open settings">
                <IconButton
                  onMouseEnter={handleMenuOpen}
                  color="inherit"
                  sx={{
                    backgroundColor: colors.gray,
                    color: colors.black,
                    '&:hover': {
                      backgroundColor: colors.lightGray2,
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: 30,
                      height: 30,
                      fontSize: 'large',
                      fontWeight: 'bold',
                      bgcolor: 'inherit',
                      color: colors.black,
                    }}
                  >
                    {getAvatarLetters(currentUser?.emailId)}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  onMouseEnter: () => setIsMenuOpen(true),
                  onMouseLeave: () => setIsMenuOpen(false),
                }}
              >
                <MenuItem 
                  onClick={handleSettings} 
                  selected={currentPath === '/settings/profile'}
                  sx={{'&:hover': {
                      backgroundColor: colors.primaryHover, 
                      color: colors.white,
                      '& .MuiListItemIcon-root': {
                        color: colors.white,
                      },
                    },
                  }}
                >
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Settings</Typography>
                </MenuItem>

                <MenuItem 
                  onClick={handleLogout}
                  sx={{
                    '&:hover': {
                      backgroundColor: colors.primaryHover,
                      color: colors.white,
                      '& .MuiListItemIcon-root': {
                        color: colors.white,
                      },
                    },
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Logout</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
