import axiosInstance from '../../../api/axiosWrapper';

const registerUser = async (userToRegister, firebaseUID, firebaseToken) => {
  try {
    const response = await axiosInstance.post(
      '/registerUser',
      { ...userToRegister, firebaseUID },
      {
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const userService = {
  registerUser,
};

export default userService;
