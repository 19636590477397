import React from 'react';
import { DGExportButton } from '../../styles/style.js';
import { SButton } from '../../styles/style.js';
import { randomId } from '@mui/x-data-grid-generator';
import PlaidLink from '../PlaidLink/PlaidLink.js';
import AddIcon from '@mui/icons-material/Add';
import { GridRowModes } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import { CustomGridToolbarContainer } from '../../styles/style.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const EditToolbar = ({
  setRows,
  setRowModesModel,
  loadingData,
  setLoadingData,
  setEditCount,
}) => {
  const handleClick = async () => {
    if (loadingData) {
      return;
    }

    setLoadingData(true);

    setRows((oldRows) => {
      const hasUnsaved = oldRows.some((row) => row.isNew);
      if (hasUnsaved) {
        setLoadingData(false);
        return oldRows;
      }

      const id = randomId();
      const localDate = new Date();
      const utcDate = dayjs(localDate).utc().format();

      const newRow = {
        id,
        date: utcDate,
        description: '',
        account: 'Other',
        category: '',
        amount: 0,
        isNew: true,
      };

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'date' },
      }));
      setEditCount((prevCount) => prevCount + 1);
      setLoadingData(false);

      return [newRow, ...oldRows];
    });
  };

  return (
    <CustomGridToolbarContainer>
      <PlaidLink loadingData={loadingData} setLoadingData={setLoadingData} />
      <Tooltip title="Add Transaction" placement="top" arrow>
        <SButton color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Transaction
        </SButton>
      </Tooltip>
      <DGExportButton
        printOptions={{
          print: true,
          fields: ['date', 'account', 'category', 'amount'],
          hideFooter: true,
          hideToolbar: true,
        }}
      />
    </CustomGridToolbarContainer>
  );
};

export default EditToolbar;
