import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Modal, Box, Typography, CircularProgress, Tooltip } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { colors } from '../../../themes/theme';
import { GridDeleteIcon } from '@mui/x-data-grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import uploadService from '../../services/api/upload-service';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px; 
  height: 600px; 
  background-color: #fff;
  box-shadow: 24px;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

const Container = styled.div`
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  overflow: auto; 

  svg {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    fill: ${colors.primary};
  }
`;

const ImgFlex = styled.div`
  height: 100%; 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  color: #333;
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
`;

const StyledIconButton = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.disabled ? colors.bgGray : props.color};
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${(props) => props.disabled ? colors.bgGray : props.color};
  }
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.disabled ? colors.white : props.color};
    svg {
      fill: ${(props) => props.disabled ? colors.bgGray : colors.white};
    }
  }
`;

const Flex = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin: auto;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ReceiptModal = ({ transactionId, open, handleClose, receiptUrl, reloadTransactions}) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setFileUrl(receiptUrl);
    return () => {
      setFileUrl(null);
    };
  }, [receiptUrl]);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true);
      try {
        const response = await uploadService.uploadFile(transactionId, selectedFile);
        setFileUrl(response.data.fileUrl);
        reloadTransactions();
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteReceipt = async () => {
    if (fileUrl) {
      try {
        await uploadService.deleteFile(transactionId);
        reloadTransactions();
        setFileUrl(null);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith('image/')) {
      setLoading(true);
      try {
        const response = await uploadService.uploadFile(transactionId, droppedFile);
        setFileUrl(response.data.fileUrl);
        reloadTransactions();
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="receipt-modal-title"
      aria-describedby="receipt-modal-description"
    >
      <StyledBox>
        <Container onDrop={handleDrop} onDragOver={handleDragOver}>
          {fileUrl ? (
            <StyledImage src={fileUrl} alt="Receipt" />
          ) : (
            <ImgFlex>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <ReceiptLongIcon />
                  <br />
                  <Typography variant="h5" color={colors.darkGray3}>
                    Upload a receipt to keep track of your expenses
                  </Typography>
                  <Typography variant="h6" color={colors.darkGray3}>
                    Drag & drop an image file here or click upload below to choose a file
                  </Typography>
                </>
              )}
            </ImgFlex>
          )}
        </Container>
        <Flex>
          <Tooltip title="Upload Receipt" arrow>
            <StyledIconButton color={colors.primary} onClick={handleUploadClick}>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <CloudUploadIcon />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Delete Receipt" arrow>
            <StyledIconButton color={colors.expenses} onClick={handleDeleteReceipt} disabled={!fileUrl}>
              <GridDeleteIcon />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title="Close" arrow>
            <StyledIconButton color={colors.primary} onClick={handleClose}>
              <CloseIcon />
            </StyledIconButton>
          </Tooltip>
        </Flex>
      </StyledBox>
    </StyledModal>
  );
};

export default ReceiptModal;