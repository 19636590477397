import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { colors } from "../../../../src/themes/theme";

export const Container = styled.div`
  width: calc(100% - 120px);
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 1024px;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  height: fit-content;
  padding: ${(props) => props.padding || "10px"};
  gap: 50px;
  border-radius: 8px;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 8px;
`;

export const FlexItem = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: center;
  flex-direction: ${(props) => props.dir || "row"};
  width: 100%;
`;

export const Title = styled.h6`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.black2};
`;

export const ExportButton = styled(Button)`
  background-color: ${colors.secondary};
  text-transform: none;
  border-radius: 50px;
  box-shadow: none;
  color: ${colors.white};
  &:hover {
    background-color: ${colors.hoverBg};
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black2};
  text-align: right;
  width: 100%;
`;

export const TabularContainer = styled.div`
  width: 100%;
`;

export const SectionHeader = styled.div`
    background-color: ${colors.bgGray};
    padding: 10px;
    font-weight: bold;
    padding: 10px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
`;

export const LineItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-top: 1px solid ${colors.borderColor};
  margin: 0px 12px;

  &:nth-of-type(even) {
    background-color: ${colors.white};
  }
`;

export const TotalLineContainer = styled(LineItemContainer)`
  font-weight: bold;
  margin-bottom: 20px;
`;

export const ResetButton = styled(Button)`
  background-color: ${colors.primary};
  text-transform: none;
  border-radius: 50px;
  box-shadow: none;
  color: ${colors.white};
  &:hover {
    background-color: ${colors.hoverBg};
  }
`;
