import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Modal,
  Tab,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  CustomTabs,
  ValueTypography,
  ItemFieldset,
  ItemLegend,
  StyledIconButton,
  StyledSelect,
} from '../../styles/style.js';
import { colors } from '../../../themes/theme.js';
import { parseCurrency } from '../../../lib/helpers.js';
import { useFinancialMetrics } from '../../common/hooks/useFinancialMetrics.js';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import {
  generateWeekOptions,
  generateMonthOptions,
  generateQuarterOptions,
  generateYearOptions,
  handleWeekSelectionChange,
  handleMonthSelectionChange,
  handleQuarterSelectionChange,
  handleYearSelectionChange,
} from './dateOptions';
import DateRangeIndicator from './DateRangeIndicator';
import { useDateState } from '../../common/hooks/useDateState.js';
import TimeTabsModal from './TimeTabsModal';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

function MetricsDisplay({ transactions, onTimePeriodChange, filterState }) {
  const {
    activeTimeTab,
    setActiveTimeTab,
    selectedWeeks,
    setSelectedWeeks,
    selectedMonths,
    setSelectedMonths,
    selectedQuarters,
    setSelectedQuarters,
    selectedYears,
    setSelectedYears,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
  } = useDashboardContext();

  const {
    isOpenWeek,
    handleOverWeek,
    handleCloseWeek,
    isOpenMonth,
    handleOverMonth,
    handleCloseMonth,
    isOpenQuarter,
    handleOverQuarter,
    handleCloseQuarter,
    isOpenYear,
    handleOverYear,
    handleCloseYear,
  } = useDateState();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleReset = () => {
    setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
    setEndDate(dayjs(maxDateRangeLocal).endOf('day').format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (minDateRangeLocal && maxDateRangeLocal) {
      handleReset();
    }
  }, [minDateRangeLocal, maxDateRangeLocal]);

  const handleClick = (event) => {
    const targetClassName =
      'MuiBackdrop-root MuiBackdrop-invisible MuiModal-backdrop css-g3hgs1-MuiBackdrop-root-MuiModal-backdrop';
    const isTargetBackdrop = event.target.className === targetClassName;

    if (isTargetBackdrop) {
      handleCloseWeek();
      handleCloseMonth();
      handleCloseQuarter();
      handleCloseYear();
      event.stopPropagation();
    }
  };

  const filteredTransactions = useMemo(() => {
    if (filterState === 'income') {
      return transactions.filter((transaction) => transaction.amount >= 0);
    } else if (filterState === 'expenses') {
      return transactions.filter((transaction) => transaction.amount <= 0);
    }
    return transactions;
  }, [transactions, filterState]);

  const { income, expenses, netProfit } = useFinancialMetrics(
    filteredTransactions,
    activeTimeTab,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));

  const handleTimeTabChange = (event, newValue) => {
    setActiveTimeTab(newValue);
    onTimePeriodChange(newValue);
  };

  const _netProfit = useMemo(() => parseCurrency(netProfit), [netProfit]);

  const items = [
    {
      legend: 'Sales',
      value: income,
      color: colors.sales,
    },
    {
      legend: 'Expenses',
      value: expenses,
      color: colors.expenses,
    },
    {
      legend: `Net ${_netProfit >= 0 || netProfit === '$0.00' ? 'Profit' : 'Loss'}`,
      value: netProfit,
      color:
        _netProfit >= 0 || netProfit === '$0.00'
          ? colors.sales
          : colors.expenses,
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const weekOptions = useMemo(
    () => generateWeekOptions(filteredTransactions, startDate, endDate),
    [filteredTransactions, startDate, endDate]
  );

  const monthOptions = useMemo(
    () => generateMonthOptions(filteredTransactions, startDate, endDate),
    [filteredTransactions, startDate, endDate]
  );

  const quarterOptions = useMemo(
    () => generateQuarterOptions(filteredTransactions, startDate, endDate),
    [filteredTransactions, startDate, endDate]
  );

  const yearOptions = useMemo(
    () => generateYearOptions(filteredTransactions, startDate, endDate),
    [filteredTransactions, startDate, endDate]
  );

  return (
    <>
      <Box
        sx={{
          width: '80vw',
          height: 'fit-content',
          backgroundColor: 'white',
          mb: 0,
          pb: {
            xs: 4,
            sm: 5,
            md: 6,
          },
          borderRadius: 6,
          boxShadow: colors.shadow,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!isMdOrBelow ? (
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              bottom: '14px',
              left: '35px',
            }}
          >
            <CustomTabs
              value={activeTimeTab}
              onChange={handleTimeTabChange}
              aria-label="time tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '& .MuiTab-root': {
                  minWidth: 'fit-content',
                },
              }}
            >
              <Tab
                label={
                  <StyledSelect
                    open={isOpenWeek}
                    onMouseEnter={handleOverWeek}
                    multiple
                    value={selectedWeeks}
                    onChange={(event) =>
                      handleWeekSelectionChange(
                        event,
                        weekOptions,
                        setSelectedWeeks,
                        setSelectedMonths,
                        setSelectedQuarters,
                        setSelectedYears
                      )
                    }
                    onClick={handleClick}
                    renderValue={() => 'Week'}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        onMouseLeave: handleCloseWeek,
                        style: {
                          maxHeight: 200,
                          maxWidth: 300,
                          fontSize: '14px',
                        },
                      },
                    }}
                  >
                    <MenuItem value="select-all">
                      <em>Select All</em>
                    </MenuItem>
                    {weekOptions.map((week) => (
                      <MenuItem key={week} value={week}>
                        {week}
                      </MenuItem>
                    ))}
                    {selectedWeeks.length === weekOptions.length && (
                      <MenuItem value="deselect-all">
                        <em>Deselect All</em>
                      </MenuItem>
                    )}
                  </StyledSelect>
                }
                value="week"
              />
              <Tab
                label={
                  <StyledSelect
                    open={isOpenMonth}
                    onMouseEnter={handleOverMonth}
                    multiple
                    value={selectedMonths}
                    onChange={(event) =>
                      handleMonthSelectionChange(
                        event,
                        monthOptions,
                        setSelectedWeeks,
                        setSelectedMonths,
                        setSelectedQuarters,
                        setSelectedYears
                      )
                    }
                    onClick={handleClick}
                    renderValue={() => 'Month'}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        onMouseLeave: handleCloseMonth,
                        style: {
                          maxHeight: 200,
                          maxWidth: 300,
                          fontSize: '14px',
                        },
                      },
                    }}
                  >
                    <MenuItem value="select-all">
                      <em>Select All</em>
                    </MenuItem>
                    {monthOptions.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                    {selectedMonths.length === monthOptions.length && (
                      <MenuItem value="deselect-all">
                        <em>Deselect All</em>
                      </MenuItem>
                    )}
                  </StyledSelect>
                }
                value="month"
              />
              <Tab
                label={
                  <StyledSelect
                    open={isOpenQuarter}
                    onMouseEnter={handleOverQuarter}
                    multiple
                    value={selectedQuarters}
                    onChange={(event) =>
                      handleQuarterSelectionChange(
                        event,
                        quarterOptions,
                        setSelectedWeeks,
                        setSelectedMonths,
                        setSelectedQuarters,
                        setSelectedYears
                      )
                    }
                    onClick={handleClick}
                    renderValue={() => 'Quarter'}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        onMouseLeave: handleCloseQuarter,
                        style: {
                          maxHeight: 200,
                          maxWidth: 300,
                          fontSize: '14px',
                        },
                      },
                    }}
                  >
                    <MenuItem value="select-all">
                      <em>Select All</em>
                    </MenuItem>
                    {quarterOptions.map((quarter) => (
                      <MenuItem key={quarter} value={quarter}>
                        {quarter}
                      </MenuItem>
                    ))}
                    {selectedQuarters.length === quarterOptions.length && (
                      <MenuItem value="deselect-all">
                        <em>Deselect All</em>
                      </MenuItem>
                    )}
                  </StyledSelect>
                }
                value="quarter"
              />
              <Tab
                label={
                  <StyledSelect
                    open={isOpenYear}
                    onMouseEnter={handleOverYear}
                    multiple
                    value={selectedYears}
                    onChange={(event) =>
                      handleYearSelectionChange(
                        event,
                        yearOptions,
                        setSelectedWeeks,
                        setSelectedMonths,
                        setSelectedQuarters,
                        setSelectedYears
                      )
                    }
                    onClick={handleClick}
                    renderValue={() => 'Year'}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        onMouseLeave: handleCloseYear,
                        style: {
                          maxHeight: 200,
                          maxWidth: 300,
                          fontSize: '14px',
                        },
                      },
                    }}
                  >
                    <MenuItem value="select-all">
                      <em>Select All</em>
                    </MenuItem>
                    {yearOptions.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                    {selectedYears.length === yearOptions.length && (
                      <MenuItem value="deselect-all">
                        <em>Deselect All</em>
                      </MenuItem>
                    )}
                  </StyledSelect>
                }
                value="year"
              />
              <Tab
                label="All"
                value="all"
                style={{
                  fontSize: '14px',
                  flexShrink: 0,
                }}
              />
              <DateRangeIndicator
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                minDateRangeLocal={minDateRangeLocal}
                maxDateRangeLocal={maxDateRangeLocal}
                handleReset={handleReset}
                isResetDisabled={(() => {
                  const isDisabled =
                    dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
                    dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day');
                  return isDisabled;
                })()}
              />
            </CustomTabs>
          </Box>
        ) : (
          <Box
            sx={{
              marginRight: '-15px',
              marginLeft: 'auto',
            }}
          >
            <IconButton
              onClick={openModal}
              sx={{
                position: 'relative',
                bottom: '14px',
                backgroundColor: colors.primary,
                color: 'white',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: colors.primaryHover,
                },
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Box>
        )}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            mt: 1,
          }}
        >
          {isMobile && (
            <StyledIconButton
              onClick={handlePrev}
              visibility={currentIndex !== 0}
            >
              <ChevronLeftIcon />
            </StyledIconButton>
          )}

          {isMobile ? (
            <ItemFieldset>
              <ItemLegend>{items[currentIndex].legend}</ItemLegend>
              <ValueTypography color={items[currentIndex].color}>
                {items[currentIndex].value}
              </ValueTypography>
            </ItemFieldset>
          ) : (
            items.map((item, index) => (
              <ItemFieldset key={index}>
                <ItemLegend>{item.legend}</ItemLegend>
                <ValueTypography color={item.color}>
                  {item.value}
                </ValueTypography>
              </ItemFieldset>
            ))
          )}

          {isMobile && (
            <StyledIconButton
              onClick={handleNext}
              visibility={currentIndex !== items.length - 1}
            >
              <ChevronRightIcon />
            </StyledIconButton>
          )}
        </Box>
      </Box>

      <Modal open={modalOpen} onClose={closeModal}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: 'auto',
            marginTop: '50px',
          }}
        >
          <Box
            sx={{
              width: { xs: '90vw', sm: '80vw', md: '60vw' },
              maxHeight: '90vh',
              backgroundColor: 'white',
              borderRadius: 6,
              boxShadow: colors.shadow,
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <TimeTabsModal
              onTimePeriodChange={onTimePeriodChange}
              setActiveTimeTab={setActiveTimeTab}
              minDateRangeLocal={minDateRangeLocal}
              isResetDisabled={(() => {
                const isDisabled =
                  dayjs(startDate).isSame(dayjs(minDateRangeLocal), 'day') &&
                  dayjs(endDate).isSame(dayjs(maxDateRangeLocal), 'day');
                return isDisabled;
              })()}
              maxDateRangeLocal={maxDateRangeLocal}
              handleReset={handleReset}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              selectedWeeks={selectedWeeks}
              setSelectedWeeks={setSelectedWeeks}
              weekOptions={weekOptions}
              selectedMonths={selectedMonths}
              setSelectedMonths={setSelectedMonths}
              monthOptions={monthOptions}
              selectedQuarters={selectedQuarters}
              setSelectedQuarters={setSelectedQuarters}
              quarterOptions={quarterOptions}
              selectedYears={selectedYears}
              setSelectedYears={setSelectedYears}
              yearOptions={yearOptions}
              closeModal={closeModal}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default MetricsDisplay;
