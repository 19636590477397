import React, { useState } from 'react';
import { Box, CssBaseline, Typography } from '@mui/material';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import ProfileInfo from './ProfileInfo';
import BankAccountInfo from './BankAccountInfo';
import Sidebar from '../../../../components/features/Navbar/Sidebar';
import { colors } from '../../../../themes/theme';


const drawerWidth = 240;

const SettingsPage = ({ currentUser, isLoggedIn, handleLogout, updateProfile }) => {
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/settings/profile':
        return 'Profile Information';
      case '/settings/bank-account':
        return 'Bank Accounts';
      default:
        return 'Settings';
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' , backgroundColor: colors.lightGray,}}>
      <CssBaseline />
      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        isSmallScreen={window.innerWidth < 600}
        mobileOpen={mobileOpen}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: 2, 
        }}
      >
        {location.pathname.startsWith('/settings') && (
          <Typography variant="h4" gutterBottom>
            {getPageTitle()}
          </Typography>
        )}
        <Routes>
          <Route path="/" element={<Navigate to="profile" />} />
          <Route path="profile" element={<ProfileInfo currentUser={currentUser} updateProfile={updateProfile} />} />
          <Route path="bank-account" element={<BankAccountInfo currentUser={currentUser} isLoggedIn={isLoggedIn} />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default SettingsPage;
