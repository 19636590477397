import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';
import { Typography, Paper } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { colors } from '../../../../themes/theme';
import { addCommasToNumber } from '../../Reports/addCommasToNumber';

dayjs.extend(isBetween);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        elevation={3}
        style={{ padding: '10px', backgroundColor: '#ffffff' }}
      >
        <Typography variant="subtitle2" component="p">
          {label}
        </Typography>
        <Typography variant="h8" component="p" style={{ fontWeight: 'bold' }}>
          Sales: ${addCommasToNumber(payload[0].value)}
        </Typography>
      </Paper>
    );
  }
  return null;
};

const TransactionChart = ({
  transactions,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (transactions.length > 0) {
      const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = dayjs(transaction.authorized_date).local();
        const transactionMonthStr = transactionDate.format('MMMM YYYY');
        const transactionQuarterStr = `Q${Math.floor(transactionDate.month() / 3) + 1} ${transactionDate.year()}`;
        const transactionYearStr = transactionDate.format('YYYY');

        switch (timePeriod) {
          case 'week':
            return selectedWeeks.some((week) => {
              const [start, end] = week.split(' - ');
              const year = end.split(', ')[1];
              const startDate = dayjs(`${start}, ${year}`).startOf('day');
              const endDate = dayjs(end).endOf('day');
              return transactionDate.isBetween(startDate, endDate, null, '[]');
            });
          case 'month':
            return selectedMonths.includes(transactionMonthStr);
          case 'quarter':
            return selectedQuarters.includes(transactionQuarterStr);
          case 'year':
            return selectedYears.includes(transactionYearStr);
          default:
            if (startDate && endDate) {
              const start = dayjs(startDate).startOf('day');
              const end = dayjs(endDate).endOf('day');
              return transactionDate.isBetween(start, end, null, '[]');
            }
            return true;
        }
      });

      const positiveTransactions = filteredTransactions.filter(
        (transaction) => parseFloat(transaction.amount) > 0
      );

      const aggregatedData = positiveTransactions.reduce(
        (accumulatedData, transaction) => {
          const dateKey = dayjs(transaction.authorized_date)
            .local()
            .format('MM-DD-YY');
          const amount = parseFloat(transaction.amount);
          if (!accumulatedData[dateKey]) {
            accumulatedData[dateKey] = amount;
          } else {
            accumulatedData[dateKey] += amount;
          }
          return accumulatedData;
        },
        {}
      );

      let transformedData = Object.keys(aggregatedData).map((date) => ({
        date,
        sales: aggregatedData[date].toFixed(2),
      }));

      if (transformedData.length === 1) {
        const singleDate = dayjs(transformedData[0].date, 'MM-DD-YY');
        const extendedStart = singleDate.subtract(1, 'day');
        const extendedEnd = singleDate.add(1, 'day');
        transformedData.unshift({
          date: extendedStart.format('MM-DD-YY'),
          sales: 0,
        });
        transformedData.push({
          date: extendedEnd.format('MM-DD-YY'),
          sales: 0,
        });
      }

      transformedData.sort((a, b) =>
        dayjs(a.date, 'MM-DD-YY').diff(dayjs(b.date, 'MM-DD-YY'))
      );

      setData(transformedData);
    }
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);

  const maxY = Math.max(...data.map((d) => d.sales));

  const hasSales = data.some((t) => t.sales > 0);

  if (!hasSales || data.length === 0) {
    return (
      <ResponsiveContainer
        width="100%"
        height="300px"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          x={200}
          y={100}
          textAnchor="middle"
          verticalAnchor="middle"
          style={{ fontSize: '16px', color: colors.lightGray3 }}
        >
          No data available for this period
        </Text>
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        width={700}
        height={350}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0" />
        <XAxis
          dataKey="date"
          angle={-45}
          textAnchor="end"
          height={70}
          interval="preserveStartEnd"
          tick={{ fontSize: 12 }}
        />
        <YAxis
          domain={[0, maxY]}
          tickFormatter={(value) => `$${addCommasToNumber(Math.round(value)) }`}
          tick={{ fontSize: 12 }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Area
          type="monotone"
          dataKey="sales"
          name="Total Sales"
          stroke="#1360EF"
          dot={{ stroke: '#1360EF', fill: '#ffffff', fillOpacity: 1 }}
          fillOpacity={0.5}
          fill="#1360EF"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TransactionChart;
