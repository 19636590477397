import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Snackbar,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemIcon,
  Card,
  CardContent,
  TextField,
  CardActions,
} from '@mui/material';
import axiosInstance from '../../../../api/axiosWrapper';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SavingsIcon from '@mui/icons-material/Savings';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert } from '@mui/material';
import { colors } from '../../../../themes/theme'; 
import LinkBankAccountModal from '../../../../components/common/LinkBankAccountModal';

const BankAccountInfo = ({ currentUser, isLoggedIn }) => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [verificationText, setVerificationText] = useState('');
  const [dialogType, setDialogType] = useState('');
  const [modalOpen, setModalOpen] = useState(false); 
  const [loadingData, setLoadingData] = useState(false); 

  useEffect(() => {
    const fetchBankAccounts = async () => {
      if (currentUser) {
        try {
          const response = await axiosInstance.get('/getPlaidItemsForUser');
          setBankAccounts(response.data);
        } catch (error) {
          console.error('Error fetching bank accounts:', error);
        }
      }
    };

    fetchBankAccounts();
  }, [currentUser]);

  const handleRemoveAccount = async (itemId, accountId) => {
    try {
      await axiosInstance.post('/deactivateAccount', { itemId, accountId });
      const updatedAccounts = bankAccounts.map((bank) => {
        if (bank.id === itemId) {
          return {
            ...bank,
            accounts: bank.accounts.map((account) =>
              account.id === accountId ? { ...account, is_active: 0 } : account
            ),
          };
        }
        return bank;
      });
      setBankAccounts(updatedAccounts);
      setSnackbarMessage('Bank account removed successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error removing bank account:', error);
      setSnackbarMessage('Failed to remove bank account. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleUnlinkItem = async (itemId) => {
    try {
      await axiosInstance.post('/deactivatePlaidItem', { itemId });
      const updatedItems = bankAccounts.filter((item) => item.id !== itemId);
      setBankAccounts(updatedItems);
      setSnackbarMessage('Bank item unlinked successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error unlinking bank item:', error);
      setSnackbarMessage('Failed to unlink bank item. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleLinkNewBank = () => {
    setModalOpen(true); 
  };

  const getAccountIcon = (subtype) => {
    switch (subtype) {
      case 'checking':
        return <HomeIcon sx={{ color: colors.black }} />;
      case 'savings':
        return <SavingsIcon sx={{ color: colors.black }} />;
      case 'credit':
        return <CreditCardIcon sx={{ color: colors.black }} />;
      default:
        return <AccountBalanceIcon sx={{ color: colors.black }} />;
    }
  };

  const handleOpenDialog = (itemId, accountId, type) => {
    setSelectedAccountId(accountId);
    setSelectedItemId(itemId);
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAccountId(null);
    setSelectedItemId(null);
    setDialogType('');
  };

  const handleConfirmRemove = () => {
    if (dialogType === 'account') {
      handleRemoveAccount(selectedItemId, selectedAccountId);
    } else if (dialogType === 'item') {
      handleUnlinkItem(selectedItemId);
    }
    handleCloseDialog();
  };

  return (
    <Box sx={{ padding: '20px', backgroundColor: colors.lightGray }}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'left' }}>
        Linked Accounts
      </Typography>
      <Box sx={{ textAlign: 'left', mb: 2 }}></Box>
      <Button
        variant="contained"
        onClick={handleLinkNewBank}
        sx={{
          display: 'block',
          borderRadius: '12px',
          ml: 'auto',
          mb: 2,
          backgroundColor: colors.primary,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.primaryHover,
          },
        }}
      >
        {bankAccounts.length > 0 ? 'Add Another Bank Account' : 'Connect a Bank Account'}
      </Button>
      <Divider sx={{ mb: 2 }} />
      <Box>
        {bankAccounts.map((bank) => (
          <Card key={bank.id} sx={{ mb: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccountBalanceIcon sx={{ mr: 1, color: colors.black }} />
                  <Typography variant="h6">{bank.institutionName}</Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <List>
                {bank.accounts
                  .filter((account) => account.is_active !== 0)
                  .map((account) => (
                    <ListItem key={account.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <ListItemIcon>{getAccountIcon(account.subtype)}</ListItemIcon>
                      <ListItemText
                        primary={account.name}
                        secondary={`${account.number} | ${account.type.charAt(0).toUpperCase() + account.type.slice(1)} - ${account.subtype.charAt(0).toUpperCase() + account.subtype.slice(1)}`}
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleOpenDialog(bank.id, account.id, 'account')}
                        sx={{
                          color: colors.black,
                          '&:hover': {
                            color: colors.error,
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  ))}
              </List>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                onClick={() => handleOpenDialog(bank.id, null, 'item')}
                sx={{
                  ml: 'auto',
                  borderRadius: '20px',
                  backgroundColor: colors.primary,
                  color: colors.white,
                  '&:hover': {
                    backgroundColor: colors.error,
                    borderColor: colors.error,
                    color: colors.white,
                  },
                }}
              >
                Unlink
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to remove this bank account? Type "delete" to confirm.</Typography>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            value={verificationText}
            onChange={(e) => setVerificationText(e.target.value)}
            placeholder="Type 'delete' to confirm"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmRemove}
            sx={{ color: colors.error }}
            disabled={verificationText.toLowerCase() !== 'delete'}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* Link Bank Account Modal */}
      <LinkBankAccountModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        loadingData={loadingData}
        setLoadingData={setLoadingData}
      />
    </Box>
  );
};

export default BankAccountInfo;
