import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Tooltip, Box, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { SDataGrid } from '../../styles/style.js';
import { GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid';
import { useGridApiRef } from '@mui/x-data-grid';
import DataGridSkeleton from './DataGridSkeleton.js';
import {
  addTransaction,
  editTransaction,
  markTransactionAsRemoved,
} from '../../services/api/transactions-service.js';
import { categoriesColorArray } from '../../../themes/theme.js';
import EditToolbar from './EditToolbar.js';
import QuickSearchToolbar from './QuickSearchToolbar.js';
import DeleteConfirmationDialog from '../Delete Dialog/index.js';
import { getGridColumns } from './gridColumns.js';
import TransactionFilter from './transactionFilter.js';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import ReceiptModal from './ReceiptModal';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

const FullFeaturedCrudGrid = ({
  rows,
  accounts,
  loadingData,
  setLoadingData,
  timePeriod,
  setRows,
  setOpenAlert,
  setAlertSeverity,
  setAlertMessage,
  reloadTransactions,
  onFilterChange,
  categories,
  setIsEditing,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [rowModesModel, setRowModesModel] = useState({});
  const { toggleValue, setToggleValue } = useDashboardContext();
  const [isRowValid, setIsRowValid] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [editCount, setEditCount] = useState(0);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [currentReceiptUrl, setCurrentReceiptUrl] = useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const apiRef = useGridApiRef();
  const isPrintMedia = useMediaQuery('print');

  useEffect(() => {
    if (editCount > 0) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [editCount, setIsEditing]);

  useEffect(() => {
    return () => {
      setRows((prevRows) => prevRows.filter((row) => !row.isNew));
    };
  }, [setRows]);

  const transactionCategoryColorMap = useMemo(() => {
    return categories.allCategories.reduce((acc, category, index) => {
      acc[category.value] =
        categoriesColorArray[index % categoriesColorArray.length];
      return acc;
    }, {});
  }, [categoriesColorArray, categories]);

  const validateRow = (params) => {
    const hasError =
      !params.props.value ||
      params.props.value === '' ||
      params.props.value === 0;
    if (hasError && !isRowValid) {
      return { ...params.props, error: hasError };
    }
    return { ...params.props, error: false };
  };

  const createAlert = (severity, message) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setOpenAlert(true);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      const newRowModesModel = {
        ...prevRowModesModel,
        [id]: { mode: GridRowModes.Edit },
      };
      setEditCount((prevCount) => prevCount + 1);
      return newRowModesModel;
    });
  };

  const handleSaveClick = async (id) => {
    setRowModesModel((prevRowModesModel) => {
      const newRowModesModel = {
        ...prevRowModesModel,
        [id]: { mode: GridRowModes.View },
      };
      if (
        prevRowModesModel[id] &&
        prevRowModesModel[id].mode !== GridRowModes.Edit
      ) {
        setEditCount((prevCount) => prevCount - 1);
      }
      return newRowModesModel;
    });
  };

  const handleProcessRowUpdate = async (updatedRow) => {
    const { id, isNew } = updatedRow;
    const rowKeys = Object.keys(updatedRow);

    let disableSave = 0;

    // Iterate through fields to make sure all values are valid before sending to server
    for (const key of rowKeys) {
      const value = updatedRow[key];
      if (value === '' || value === 0 || value === undefined) {
        setIsRowValid(false);

        // Call setEditCellValue to trigger preProcessEditCellProps mark invalid fields
        await apiRef.current.setEditCellValue({
          id: id,
          field: key,
          value: value,
        });
        disableSave++;
      }
    }
    if (disableSave) {
      createAlert(
        'warning',
        'Please make sure all necessary fields are filled out and try again.'
      );
      return;
    }
    setIsRowValid(true);

    updatedRow.category_id = categories.categoryIdMap[updatedRow.category]?.id;

    if (isNew) {
      saveNewTransaction(id, updatedRow);
    } else {
      editExistingTransaction(id, updatedRow);
    }

    const newRow = { ...updatedRow, isNew: false };
    setRows(rows.map((row) => (row.id === updatedRow.id ? newRow : row)));
    return newRow;
  };

  const saveNewTransaction = async (id, addedRow) => {
    try {
      const addRowResponse = await addTransaction(addedRow);
      reloadTransactions();
      if (addRowResponse.status === 200) {
        createAlert('success', 'Transaction successfully added.');

        const newId = addRowResponse.data.transaction_id;

        /**
         * Manually update `rows` state so the new row has the server-side `transaction_id`
         * for the `id` field instead of the temporary, client-generated id
         *
         * NOTE: this will log a non-breaking error since we're not using MUI's built-in premium methods
         * but it still works :)
         */

        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id ? { ...addedRow, id: newId } : row
          )
        );
        setRowModesModel((prevRowModesModel) => {
          const newRowModesModel = {
            ...prevRowModesModel,
            [id]: { mode: GridRowModes.View },
          };
          setEditCount((prevCount) => prevCount - 1);
          return newRowModesModel;
        });
        await reloadTransactions();
      }
    } catch (error) {
      createAlert('error', 'Unable to add transaction. Please try again.');
      console.error(error);
      return;
    }
  };

  const editExistingTransaction = async (id, addedRow) => {
    try {
      const editRowResponse = await editTransaction(id, addedRow);
      if (editRowResponse.status === 200) {
        setRowModesModel((prevRowModesModel) => {
          const newRowModesModel = {
            ...prevRowModesModel,
            [id]: { mode: GridRowModes.View },
          };
          setEditCount((prevCount) => prevCount - 1);
          return newRowModesModel;
        });
        createAlert('success', 'Transaction successfully modified.');
        await reloadTransactions();
      }
    } catch (error) {
      createAlert('error', 'Unable to modify transaction. Please try again.');
      return;
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedRowId(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const deleteRowResponse = await markTransactionAsRemoved(selectedRowId);
      if (deleteRowResponse.status === 200) {
        createAlert('success', 'Transaction successfully removed');
        setRows(rows.filter((row) => row.id !== selectedRowId));
        await reloadTransactions();
        setSelectedRowId(null);
      }
    } catch (error) {
      createAlert('error', 'Unable to remove transaction. Please try again.');
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      const newRowModesModel = {
        ...prevRowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      };
      setEditCount((prevCount) => prevCount - 1);
      return newRowModesModel;
    });
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleToggleChange = (event) => {
    const filter = event.target.value;
    setToggleValue(filter);
    onFilterChange(filter);
  };

  const handleUploadReceiptClick = (id) => {
    const row = rows.find((row) => row.id === id);
    const receiptUrl = row?.receiptUrl || null;
    setCurrentRowId(id);
    setCurrentReceiptUrl(receiptUrl);
    setReceiptModalOpen(true);
  };

  const handleCloseReceiptModal = () => {
    setReceiptModalOpen(false);
    setCurrentRowId(null);
    setCurrentReceiptUrl(null);
    setTimeout(() => {
      document.body.removeAttribute('style');
    }, 0);
  };

  const filterRowsByPeriod = useCallback(
    (rows) => {
      let filteredRows = [];

      switch (timePeriod) {
        case 'week':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date);
            return selectedWeeks.some((week) => {
              const [start, end] = week.split(' - ');
              const startOfSelectedWeek = dayjs(
                `${start}, ${end.split(', ')[1]}`
              ).startOf('day');
              const endOfSelectedWeek = dayjs(end).endOf('day');
              return rowDate.isBetween(
                startOfSelectedWeek,
                endOfSelectedWeek,
                null,
                '[]'
              );
            });
          });
          break;
        case 'month':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date).format('MMMM YYYY');
            return selectedMonths.includes(rowDate);
          });
          break;
        case 'quarter':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const date = dayjs(row.date);
            const quarter = `Q${date.quarter()} ${date.format('YYYY')}`;
            return selectedQuarters.includes(quarter);
          });
          break;
        case 'year':
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date).format('YYYY');
            return selectedYears.includes(rowDate);
          });
          break;
        case 'all':
          const start = dayjs(startDate).startOf('day');
          const end = dayjs(endDate).endOf('day');
          filteredRows = rows.filter((row) => {
            if (row.isNew) return true;
            const rowDate = dayjs(row.date);
            return rowDate.isBetween(start, end, null, '[]');
          });
          break;
        default:
          filteredRows = rows;
          break;
      }

      return filteredRows.filter((row) => {
        if (toggleValue === 'income') {
          return row.amount >= 0;
        } else if (toggleValue === 'expenses') {
          return row.amount <= 0;
        }
        return true;
      });
    },
    [
      timePeriod,
      toggleValue,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      startDate,
      endDate,
    ]
  );
  const filteredRows = useMemo(
    () => filterRowsByPeriod(rows),
    [rows, filterRowsByPeriod]
  );

  const columns = getGridColumns({
    accounts,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleDeleteClick,
    handleUploadReceiptClick,
    rowModesModel,
    validateRow,
    isPrintMedia,
    transactionCategoryColorMap,
    categories,
  });

  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh - 150px)',
          width: '80vw',
          position: 'relative',
          mb: '100px',
        }}
      >
        <DeleteConfirmationDialog
          isOpen={dialogOpen}
          onClose={handleClose}
          onConfirm={handleConfirmDelete}
        />
        <SDataGrid
          ignoreDiacritics
          apiRef={apiRef}
          rows={loadingData ? [] : filteredRows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={(updatedRow) => handleProcessRowUpdate(updatedRow)}
          onProcessRowUpdateError={(error) => console.log(error)}
          loading={loadingData}
          slots={{
            loadingOverlay: DataGridSkeleton,
            toolbar: () => (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 20,
                  }}
                >
                  <Typography variant="h5" className="no-print">
                    Transactions
                  </Typography>
                  <Tooltip title="Filter by Transaction" placement="top" arrow>
                    <TransactionFilter
                      toggleValue={toggleValue}
                      handleToggleChange={handleToggleChange}
                    />
                  </Tooltip>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginLeft: 'auto',
                    }}
                    className="no-print"
                  >
                    <EditToolbar
                      setRows={setRows}
                      setRowModesModel={setRowModesModel}
                      loadingData={loadingData}
                      setLoadingData={setLoadingData}
                      setEditCount={setEditCount}
                    />
                  </div>
                  <QuickSearchToolbar />
                </div>
              </>
            ),
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
        />
      </Box>
      {receiptModalOpen && (
        <ReceiptModal
          transactionId={currentRowId}
          open={receiptModalOpen}
          handleClose={handleCloseReceiptModal}
          receiptUrl={currentReceiptUrl}
          reloadTransactions={reloadTransactions}
        />
      )}
    </>
  );
};

export default FullFeaturedCrudGrid;
