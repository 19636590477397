import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const useDateRange = (minDateRange, maxDateRange) => {
  const [startDate, setStartDateState] = useState(null);
  const [endDate, setEndDateState] = useState(null);

  useEffect(() => {
    if (minDateRange && maxDateRange) {
      const startOfDayLocal = dayjs(minDateRange).startOf('day');
      const endOfDayLocal = dayjs(maxDateRange).endOf('day');

      setStartDateState(startOfDayLocal.toDate());
      setEndDateState(endOfDayLocal.toDate());
    }
  }, [minDateRange, maxDateRange]);

  const setStartDate = (date) => {
    const startLocal = dayjs(date).startOf('day');
    setStartDateState(startLocal.toDate());
  };

  const setEndDate = (date) => {
    const endLocal = dayjs(date).endOf('day');
    setEndDateState(endLocal.toDate());
  };

  const formatDate = (date) => {
    if (!date) return '';
    return dayjs(date).format('MMM D, YYYY');
  };

  return {
    startDate: dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'),
    endDate: dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss'),
    setStartDate,
    setEndDate,
    formatDate,
  };
};

export default useDateRange;
