import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/features/Navbar/Navbar';
import Home from './pages/Dashboard/Home';
import PosSelectionPage from './components/features/PosSelectionPage/PosSelectionPage';
import LandingPage from './components/features/LandingPage/LandingPage';
import Footer from './components/features/Footer/Footer';
import DataGridPage from './pages/Dashboard/Home/DataGridPage';
import DataVisualizationsPage from './pages/Dashboard/Home/DataVisualizationsPage';
import ResetPassword from './components/features/Login/ResetPassword';
import axiosInstance from './api/axiosWrapper';
import CircularProgress from '@mui/material/CircularProgress';
import ReportsPage from './components/features/Reports';
import LinkBankAccountPage from './components/features/PlaidLink/LinkAccount';
import SettingsPage from './pages/Dashboard/Home/settings/settingsPage';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [requireAuth, setRequireAuth] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  let navigate = useNavigate();

  const checkSessionLogin = useCallback(async () => {
    if (window.location.pathname === '/login/reset') {
      setRequireAuth(true);
      return;
    }
    try {
      const response = await axiosInstance.post('/auth', {})
      if (response.status === 200) {
        setIsLoggedIn(true);
        setCurrentUser(response.data.user);
        if (window.location.pathname === '/') {
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        console.warn(`User is not logged in.`);
        setRequireAuth(true);
      }
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
      return;
    }
  }, [navigate]);

  useEffect(() => {
    checkSessionLogin();
  }, [checkSessionLogin]);

  const handleLogout = async () => {
    try {
      await axiosInstance.post('/logoutUser');
      setCurrentUser(null);
      setIsLoggedIn(false);
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (!isLoggedIn && !requireAuth) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="App">
        <Helmet>
          <title>ONEBIT</title>
        </Helmet>
        <Navbar currentUser={currentUser} isLoggedIn={isLoggedIn} handleLogout={handleLogout} />

        <Routes>
          <Route
            path="/"
            element={<LandingPage setIsLoggedIn={setIsLoggedIn} setCurrentUser={setCurrentUser} />}
          />
          <Route path="/login/reset" element={<ResetPassword />} />
          <Route path="/setpos" element={<PosSelectionPage />} />
          <Route path="/link-bank-account" element={<LinkBankAccountPage />} />
          <Route path="/dashboard" element={<Home />}>
            <Route index element={<Navigate to="chart" replace />} />
            <Route path="chart" element={<DataVisualizationsPage />} />
            <Route path="datagrid" element={<DataGridPage />} />
            <Route path="reports" element={<ReportsPage />} />
          </Route>
          <Route 
            path="/settings/*" 
            element={<SettingsPage currentUser={currentUser} isLoggedIn={isLoggedIn} handleLogout={handleLogout} />} 
          />
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;