import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import AddLinkIcon from '@mui/icons-material/AddLink';
import axiosInstance from '../../../api/axiosWrapper';
import { LinkButton } from './style.js';
import { Tooltip, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const PLAID_ENV = process.env.REACT_APP_PLAID_ENV || 'sandbox';
const USE_WEBHOOK = process.env.REACT_APP_USE_WEBHOOK || false;

const PlaidLink = ({ loadingData, setLoadingData }) => {
  const [linkToken, setLinkToken] = useState(null);

  const generateLinkToken = async () => {
    try {
      const { data } = await axiosInstance.post('/createPlaidLinkToken', {});
      if (!data.success) {
        console.error('Failed to create Plaid link token');
        return;
      }
      const linkToken = data.data.linkToken;
      localStorage.setItem('link_token', linkToken.link_token);
      setLinkToken(linkToken.link_token);
    } catch (error) {
      console.error('Error generating link token:', error);
    }
  };

  const onSuccess = async (public_token) => {
    const exchangePublicTokenForAccessToken = async () => {
      try {
        if (!loadingData) {
          setLoadingData(true);
        }
        console.log('Calling exchangePublicTokenForAccessToken...');
        const exchangePublicTokenRes = await axiosInstance.post(
          '/exchangePlaidPublicToken',
          { publicToken: public_token }
        );
        if (!exchangePublicTokenRes?.data?.success) {
          console.log('Bad response from exchangePublicTokenForAccessToken');
          return null;
        }
        console.log('Success! Plaid link flow complete.');
        return exchangePublicTokenRes.data;
      } catch (error) {
        console.error('Error exchanging public token:', error);
        return null;
      }
    };

    try {
      const { data: authData } = await axiosInstance.post('/auth');
      const plaidResponse = await exchangePublicTokenForAccessToken();
      if (!plaidResponse) {
        console.error('Account linking failed, please try again later!');
        return;
      }

      if (PLAID_ENV === 'sandbox') {
        if (USE_WEBHOOK === 'true') {
          console.log("Manually generating a webhook since we're running Plaid in sandbox mode.");
          const userId = authData.userId;
          await callSyncPlaidTransactionsFromWebhook(userId);
        } else {
          console.log('Manually calling sync transactions for sandbox mode...');
          const itemId = plaidResponse.itemId;
          await callSyncPlaidTransactionsDirectly(itemId);
        }
      }

      // Wait for the batch synchronization to complete
      console.log('Waiting for batch synchronization to complete...');
      
      // Introduce a short delay to ensure data availability
      await new Promise(resolve => setTimeout(resolve, 9000));
      
      const syncResponse = await callSyncPlaidTransactionsDirectly(plaidResponse.itemId);
      console.log('Sync Response:', syncResponse);  

      if (syncResponse.data && syncResponse.status === 200) {
        window.location.href = '/dashboard';
      } else {
        console.error('Batch synchronization failed. Please try again later.');
      }
    } catch (error) {
      console.error('Error during onSuccess:', error);
    } finally {
      setLoadingData(false);
    }
  };

  const callSyncPlaidTransactionsDirectly = async (itemId) => {
    try {
      console.log('Calling syncPlaidTransactions directly for item', itemId);
      const response = await axiosInstance.post('/syncPlaidTransactions', { itemId });
      console.log('Success!', response);
      return response;
    } catch (error) {
      console.error('Error syncing transactions directly:', error);
      return { data: { success: false } };
    }
  };

  const callSyncPlaidTransactionsFromWebhook = async (userId) => {
    try {
      console.log('Simulating a Plaid webhook sync update...');
      const webhookResponse = await axiosInstance.post('/plaidDebug/generate_plaid_webhook', { userId });
      console.log('Success!', webhookResponse);
    } catch (error) {
      console.error('Unable to simulate webhook update. Error:', error);
    }
  };

  useEffect(() => {
    generateLinkToken();
  }, []);

  const onExit = (error, metadata) => {
    console.log(`Exited early. Error: ${JSON.stringify(error)} Metadata: ${JSON.stringify(metadata)}`);
  };

  const config = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  

  return (
    <Box>
      {loadingData ? (
        <CircularProgress />
      ) : (
        <Tooltip title="Link Accounts" placement="top" arrow>
          <LinkButton onClick={open} disabled={!ready} startIcon={<AddLinkIcon />}>
            Link Accounts
          </LinkButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default PlaidLink;
