import Signup from '../Register/Signup.js';
import Signin from '../Login/Signin';
import React, { useState } from 'react';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';
import firebaseAuth from '../../services/api/firebase-service.js';
import userService from '../../services/api/user-service.js';
import {
  FIREBASE_ERRORS,
  GENERIC_SIGNUP_ERROR,
} from '../../../data/constants.js';

const LandingPage = (props) => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  let navigate = useNavigate();

  const [user, setUser] = useState({
    emailId: '',
    password: '',
  });

  const [registerUser, setRegisterUser] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    confirmPassword: '',
  });

  const checkConfirmPasswordMatch = (registerUser) =>
    !(registerUser.password !== registerUser.confirmPassword);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let nextPage = '/dashboard';
    if (!showSignIn) {
      try {
        if (!checkConfirmPasswordMatch(registerUser)) {
          throw new Error('Passwords do not match. Please try again.');
        }

        await firebaseAuth.createFirebaseUser(
          registerUser.emailId,
          registerUser.password
        );

        const firebaseToken = await firebaseAuth.getFirebaseIdToken();
        const firebaseUID = await firebaseAuth.getFirebaseUID();

        const newUser = await userService.registerUser(
          registerUser,
          firebaseUID,
          firebaseToken
        );

        if (newUser.data.registeredUser.signupState == 0) {
          nextPage = '/link-bank-account';
        }

        props.setCurrentUser(newUser.data.registeredUser); 
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          setShowSignIn(true);
          setUser({ ...user, emailId: registerUser.emailId });
          setErrorMessage(
            `An account associated with this email already exists. Please sign in.`
          );
        } else {
          setErrorMessage(`Unable to register user! Please try again.`);
        }
        return;
      }
    }

    try {
      const loginReq = {
        emailId: registerUser?.emailId || user.emailId,
        password: registerUser?.password || user.password,
      };
      const loginRes = await firebaseAuth.firebaseEmailPassLogin(loginReq);
      if (loginRes.data.user.signupState == 0) {
        nextPage = '/link-bank-account';
      }

      if (loginRes.status === 200) {
        props.setIsLoggedIn(true); //TODO: doing this manually because we don't have auth context set up yet
        props.setCurrentUser(loginRes.data.user); // Update currentUser state
        navigate(nextPage);
      }
    } catch (error) {
      const errorMessage = FIREBASE_ERRORS[error?.code] || GENERIC_SIGNUP_ERROR;
      setErrorMessage(errorMessage);
    }
  };

  if (showSignIn) {
    return (
      <Signin
        handleSubmit={handleSubmit}
        user={user}
        setUser={setUser}
        handleSignUpClick={() => {
          setShowSignIn(false);
          setErrorMessage('');
        }}
        handleResetPasswordClick={() => navigate('/login/reset')}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    );
  } else {
    return (
      <Signup
        handleSubmit={handleSubmit}
        registerUser={registerUser}
        setRegisterUser={setRegisterUser}
        handleSignInClick={() => {
          setShowSignIn(true);
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    );
  }
};

export default LandingPage;
