import React, { useState, useRef } from 'react';
import LinkAccountsPage from './LinkAccountsPage';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const LinkBankAccountPage = () => {
  const loadPlaidWidget = true;
  const apiRef = useRef(null);
  const [loadingData, setLoadingData] = useState(false);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="calc(100vh - 64px)"
    >
      {loadingData ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Linking your bank account...
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            This may take a few moments. Please do not close the browser.
          </Typography>
        </Box>
      ) : (
        <LinkAccountsPage
          loadPlaidWidget={loadPlaidWidget}
          apiRef={apiRef}
          key={loadPlaidWidget}
          loadingData={loadingData}
          setLoadingData={setLoadingData}
        />
      )}
    </Box>
  );
};

export default LinkBankAccountPage;
